import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import config from '../config';

import { User } from './user';
import { take } from 'rxjs/internal/operators/take';

@Injectable({ providedIn: 'root' })
export class ServidorService {
  constructor(private http: HttpClient) { }

  async getEstados() {
    return await this.http.get<any[]>(`${config.api.production_link}/enderecos/estados`).toPromise();
  }
  async getOrgaosExpedidores() {
    return await this.http.get<any[]>(`${config.api.production_link}/servidor/orgaosexpedidores`).toPromise();
  }
  async getOrgaosAtivosPorCidade(cidade) {
    return await this.http.get<any[]>(`${config.api.production_link}/enderecos/estados/cidades/orgaos/todos/ativo?cidade=${cidade}`).toPromise();
  }
  async uploadServidor(usuario, servidor, endereco, documentos, dependentes, temposPassado, movimentos, contatos, radio){
    return await this.http.post<any>(`${config.api.production_link}/servidor/cadastro/facil/servidor?usuario=${usuario}`, {servidor, endereco, documentos, dependentes, temposPassado, movimentos, contatos, radio}).toPromise();
  }
  async agenda(id_servidor, status){
    return await this.http.post<any>(`${config.api.production_link}/servidor/cadastro/facil/agendareuniao/servidor?servidor=${id_servidor}`, {status}).toPromise();
  }
  async getServidorCadastrado(usuario){
    return await this.http.get<any>(`${config.api.production_link}/servidor/cadastro/facil?usuario=${usuario}`).toPromise();
  }
  async uploadArquivosServidor(idServidor, files){
    try {
      console.log('chamando', idServidor)
      var result =  await this.http.post<any>(`${config.api.production_link}/servidor/cadastro/facil/files?servidor=${idServidor}`, {files}).toPromise();
      return result;
    }
    catch(error) {
      console.log(error)
    }
   
  }
  async getUrlForUploadS3(body){
    try {
      var result =  await this.http.post<any>(`${config.api.production_link}/servidor/arquivo/url`, body).toPromise();
      return result;
    }
    catch(error) {
      console.log(error)
    }
   
  }
  getValidLink(link: string) {
    return (link.substr(link.length - 1, 1) === '/') ? link : link + '/';
  }

  async uploadForS3(url, file) {
    try {
      const conditionUrl = document.URL.indexOf('localhost') > -1;
      const urlBase =  conditionUrl ? this.getValidLink(config.api.development_link) : this.getValidLink(config.api.production_link);
      const urlToSend = urlBase + url;
      const fileToSend = file.file;
      const optionsFile = { headers: { 'Content-Type': file.type, 'x-amz-acl': 'public-read' } };
      const uploadFile = await this.http.put(url, fileToSend, optionsFile).toPromise();
      console.log(uploadFile);
    } catch (error) {
      if (error.response) {
        // A response was received but it indicates an error (status code out of range of 2xx)
        console.log('Error status:', error.response.status);
        console.log('Error data:', error.response.data);
      } else if (error.request) {
        // No response was received after the request was made
        console.log('Error request:', error.request);
      } else {
        // An error occurred during setting up the request
        console.log('Error message:', error.message);
      }
      console.log('Error config:', error.config);
    }
  }
  async getTipoDependencia() {
    return await this.http.get<any[]>(`${config.api.production_link}/servidor/tiposdependencia`).toPromise();
  }
  async deleteServidor(servidor) {
    return await this.http.get<any>(`${config.api.production_link}/servidor/deletar?id_servidor=${servidor}`).toPromise();
  }
  async getMotivosInicioDependencia() {
    return await this.http.get<any[]>(`${config.api.production_link}/servidor/motivosiniciodependencia`).toPromise();
  }
  async getTiposLogradouro() {
    return await this.http.get<any[]>(`${config.api.production_link}/enderecos/tiposlogradouro`).toPromise();
  }
  async getCidadesPorEstado(estado) {
    return await this.http.get<any[]>(`${config.api.production_link}/enderecos/estados/cidades?estado=${estado}`).toPromise();
  }
  async getEscolaridade() {
    return await this.http.get<any[]>(`${config.api.production_link}/servidor/escolaridade`).toPromise();
  }
  async getEstadoCivil() {
    return await this.http.get<any[]>(`${config.api.production_link}/servidor/estadocivil`).toPromise();
  }
}
