import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import {AppLoaderComponent} from './components/app-loader/app-loader.component';
import {NgxMaskModule} from 'ngx-mask';
import {NgxCurrencyModule} from 'ngx-currency';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        SearchPipe,
        AppLoaderComponent,
        NgxMaskModule,
        ReactiveFormsModule,
        NgxCurrencyModule,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        NgxMaskModule,
        NgxCurrencyModule
    ],
    declarations: [
        SearchPipe,
        AppLoaderComponent,
    ],
    providers: [
        ThemeConstantService
    ]
})

export class SharedModule { }
