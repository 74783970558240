import { Component } from '@angular/core'
import { FormBuilder, FormGroup,  Validators } from '@angular/forms';
import config from '../../../config';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from "rxjs/operators";
import {AuthenticationService} from "../../../_helpers/authentication.service";
import App from "../../../helpers/app";

@Component({
    templateUrl: './login.component.html'
})

export class LoginComponent {
    loginForm: FormGroup;
    config = config;
    passwordVisible = false;
    loading = false
    async submitForm() {
      const self = this;
        for (const i in this.loginForm.controls) {
            this.loginForm.controls[ i ].markAsDirty();
            this.loginForm.controls[ i ].updateValueAndValidity();
        }

        if(self.loginForm.valid){
          self.loading = true
         self.authenticationService.login(self.loginForm.get('userName').value, self.loginForm.get('password').value)
            .pipe(first())
            .subscribe(
              data => {
                const currentUser = self.authenticationService.currentUserValue;
                const json = JSON.parse(JSON.stringify(currentUser))
                self.loading = false

                /*self.app.alert(`Sucesso`, `Login efetuado com sucesso!`, `success`);*/
                self.router.navigateByUrl('/home');
              },
              error => {
                self.loading = false
                self.app.alert(`Erro`, `Usuário ou senha incorretos :/`, `error`);
              });
        }
    }

    constructor(
      private fb: FormBuilder,
      private router: Router,
      private app: App,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            userName: [ null, [ Validators.required ] ],
            password: [ null, [ Validators.required ] ]
        });
    }

}
