import { SideNavInterface } from '../../interfaces/side-nav.type';

interface SideNavInterfaceS extends SideNavInterface{
  soon ?: boolean
}

export const ROUTES: SideNavInterfaceS[] = [
  {
    path: '/home',
    title: 'Inicio',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
    todos: true
  },
];
