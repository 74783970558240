import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import {DashboardModule} from './pages/dashboard/dashboard.module';
import {AuthenticationModule} from './pages/authentication/authentication.module';
import {AuthGuard} from "./_helpers";

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () => DashboardModule
          }
        ]
      , canActivate: [AuthGuard]
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
          {
            path: 'authentication',
            loadChildren: () => AuthenticationModule
          }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            useHash: false,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
