import {Component} from '@angular/core';
import {ThemeConstantService} from '../../services/theme-constant.service';
import App from '../../../helpers/app';
import {ActivatedRoute, Route, Router} from '@angular/router';
import config from '../../../config';
import {AuthenticationService} from "../../../_helpers/authentication.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent {

  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  user: any = {};
  config = config;

  constructor(private themeService: ThemeConstantService, private app: App, private router: Router,
              private authenticationService: AuthenticationService) {

  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
  }




  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  async logout() {
    const self = this;
    if (await self.app.confirm(`Logout`, `Você deseja sair do sistema?`)) {
      self.authenticationService.logout();
      self.router.navigateByUrl('/authentication');
    }
  }
}
