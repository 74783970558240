import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ServidorService } from '../_helpers/servidor.service';

declare var Calendly: any;

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.css']
})
export class CalendlyComponent implements OnInit {
  id_servidor: string;
  @ViewChild('calendlycontainer', { static: true }) calendlycontainer: ElementRef;

  constructor(private route: ActivatedRoute, private servidorService: ServidorService) { }

  ngOnInit() {
    this.id_servidor = this.route.snapshot.paramMap.get('param');

    if (!this.isCalendlyWidgetInitialized()) {
      Calendly.initInlineWidget({
        url: 'https://calendly.com/adm-bcea',
        parentElement: this.calendlycontainer.nativeElement
      });
    }

    window.addEventListener('message', this.handleCalendlyEvent.bind(this), false);
  }

  private isCalendlyWidgetInitialized(): boolean {
    return this.calendlycontainer.nativeElement.querySelector('iframe') !== null;
  }

  async handleCalendlyEvent(event: MessageEvent) {
    if (event.origin === 'https://calendly.com') {
      if (event.data.event && event.data.event === 'calendly.event_scheduled') {
        console.log('Evento agendado no Calendly:', event.data.payload);

        try {
          if (event.data.payload) {
             await this.servidorService.agenda(this.id_servidor, "Reunião Agendada");
           
          } else {
            console.error('Payload do evento não está definido.');
          }
        } catch (error) {
          console.error('Erro ao agendar reunião:', error);
        }
      }
    }
  }


}
