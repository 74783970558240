import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent{

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;

  user: {} = {};

  constructor( private themeService: ThemeConstantService) {
    const self = this;
  }
  verificaItem(item){
    const self = this;
    let liberado = true
    if(item.admin==1){
      // @ts-ignore
      if(self.user.type!==1){
        liberado = false
      }
    }else if(item.admin==0){
      // @ts-ignore
      if(self.user.type!==0){
        liberado = false
      }
    }
    return liberado
  }

    ngOnInit(): void {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }
}
