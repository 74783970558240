import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import {ThemeConstantService} from '../../shared/services/theme-constant.service';
import {LoginComponent} from './login/login.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        AuthenticationRoutingModule
    ],
    declarations: [
      LoginComponent
    ],
    providers: [
      ThemeConstantService
    ],
})

export class AuthenticationModule {}
