import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { SharedModule } from '../../shared/shared.module';
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { ThemeConstantService } from '../../shared/services/theme-constant.service';

import { DefaultDashboardComponent } from './default/default-dashboard.component';
import { CalendlyComponent } from "../../calendly/calendly.component";
import { WithBreadcrumbDashboardComponent } from './with-breadcrumb/with-breadcrumb-dashboard.component';


import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        DashboardRoutingModule
    ],
    exports: [],
    declarations: [
        DefaultDashboardComponent,
        WithBreadcrumbDashboardComponent,
        CalendlyComponent,
    ],
    providers: [
        ThemeConstantService
    ],
})
export class DashboardModule { }
