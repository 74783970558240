export default {
  api: {
    production_link: 'https://glomdjzpo5.execute-api.sa-east-1.amazonaws.com/v1',
    development_link: 'https://glomdjzpo5.execute-api.sa-east-1.amazonaws.com/v1'
/*    production_link: 'http://localhost:4000',
    development_link: 'http://localhost:4000'*/
  },
  me: {
    cache_name: 'user',
    login_field: 'Usuário'
  },
  system: {
    register: true,
    name: 'Censo Previdenciário',
    copyright: '© 2020 BRPREV - Censo Previdenciário',
    logo: 'assets/images/logo/logo.png',
    recover_password: 'sms'
  },
  developer: {
    name: '',
    link: ''
  }
};
/*  api: {
    production_link: 'http://localhost:4000',
    development_link: 'http://localhost:4000'
  },*/
