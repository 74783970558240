import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultDashboardComponent } from './default/default-dashboard.component';
import { CalendlyComponent } from "../../calendly/calendly.component";

const routes: Routes = [
    {
        path: 'home',
        component: DefaultDashboardComponent,
        data: {
            title: 'Home ',
            headerDisplay: "none"
        }
    },
    {
      path: 'calendly/:param',
      component: CalendlyComponent,
      data: {
        title: 'Agenda ',
        headerDisplay: "none"
        
    }
  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule { }
